<template>
  <v-card variant="flat" density="compact" class="pa-2">
    <v-card-title class="pa-2 page-title">
      Create Task
    </v-card-title>
    <v-card-text>
      <v-row no-gutters dense class="py-5">
        <v-col cols="12" class="pt-2" style="display: flex;">
          <!-- <v-radio-group inline v-model="formData.priority">
  <v-radio label="Low" color="#84DA5F" value="Low"></v-radio>
  <v-radio label="Medium" color="#F9F55E" value="Medium"></v-radio>
  <v-radio label="High" color="red" value="High"></v-radio>
</v-radio-group> -->

          <button class="pButton" :variant="isClicked ? null : 'outlined'" :class="(isClicked ? 'low' : '')"
            @click="toggleButtonStates('isClicked')">Low </button>
          <button class="pButton" :variant="isClicked1 ? null : 'outlined'" :class="(isClicked1 ? 'medium' : '')"
            @click="toggleButtonStates('isClicked1')" >Medium</button>
          <button class="pButton" :variant="isClicked2 ? null : 'outlined'" :class="(isClicked2 ? 'high' : '')"
            @click="toggleButtonStates('isClicked2')" >High</button>
          <button class="pButton" :variant="isClicked3 ? null : 'outlined'" :class="(isClicked3 ? 'critical' : '')"
            @click="toggleButtonStates('isClicked3')">Critical</button>
        </v-col>
        <!-- <v-col cols="12" class="pt-6 py-2">
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Due Date</v-label>
          <DatePicker :showReadOnly="showReadOnly" :data="formData.date" :placeholder="'Date'"
            @selectDate="selectDate($event)" :disabled="showReadOnly" />
          <TextFieldComponent class="date-picker" density="compact" placeholder="Due Date" type="datetime-local" showtime
            hoursFormat="24" v-model="formData.end_date" :rules="dueDateRule" :min="currentDateTime()"
            :error-messages="validate && !formData.end_date ? ['Due Date is required'] : []" /> -->
          <!-- <DatePicker :showReadOnly="showReadOnly" :data="formData.date" :placeholder="'Date'"
            @selectDate="selectDate($event)" :disabled="showReadOnly" /> -->
        <!-- </v-col> -->
        <v-col cols="12" class="pt-6 py-2">
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Estimate</v-label>
          <TextFieldComponent @keydown="prevent1($event)" density="compact" placeholder="Minutes"
            v-model="formData.estimate" />
        </v-col>
        <v-col cols="12" class="py-2" v-if="selectedProject && selectedProject.category_required == 1 && showAll">

          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600">Category
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete :items="categoryData" :placeholder="'Category'" @update:modelValue="selectCategory"
            :item-title="'name'" :disabled="showReadOnly" :item-value="'id'" v-model="formData.category_id"
            :rules="categoryRule" :menu-props="{ maxHeight: 150, maxWidth: 10, fontSize: 5 }"
            :error-messages="validate && !formData.category_id ? ['Category is required'] : []" />
        </v-col>
        <v-col cols="12" class="py-2" v-if="selectedProject && selectedProject.sub_category == 1 && showAll">

          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600">Sub Category
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete :items="subCategoryData" :placeholder="'Sub Category'" :item-title="'name'"
            :disabled="showReadOnly" :item-value="'id'" v-model="formData.sub_category_id" :rules="subCategoryRule"
            :menu-props="{ maxHeight: 150, maxWidth: 10, fontSize: 5 }"
            :error-messages="validate && !formData.sub_category_id ? ['SubCategory is required'] : []" />
        </v-col>

        <v-col cols="12" class="py-2" v-if="showAll">
          <v-label style="font-size: 12px; color: #272727; font-weight: 600">Sub Task
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete class="mt-1 custom-autocomplete" :items="taskData" :placeholder="'Sub Task'" :item-title="'name'"
            :disabled="showReadOnly" :item-value="'id'" v-model="formData.task_id" :rules="taskRule"
            :menu-props="{ maxHeight: 150, maxWidth: 10, fontSize: 5 }"
            :error-messages="validate && !formData.task_id ? ['Task is required'] : []" />
        </v-col>

        <v-col cols="12" class="py-2" v-if="showAll">
          <v-label style="font-size: 12px; color: #272727; font-weight: 600">Task Type
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete class="mt-1 custom-autocomplete" :items="issueTypeData" :placeholder="'Task Type'"
            :item-title="'name'" :disabled="showReadOnly" :item-value="'id'" v-model="formData.issue_type_id"
            :rules="taskRule" :menu-props="{ maxHeight: 150, maxWidth: 10, fontSize: 5 }"
            :error-messages="validate && !formData.task_id ? ['Issue Type is required'] : []" />
        </v-col>
        <v-col cols="12" class="py-2">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600">Next Action
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete class="mt-1 custom-autocomplete" :items="userData" :placeholder="'Next Action'"
            :item-title="'name'" :disabled="showReadOnly" :item-value="'id'" v-model="formData.next_action"
            :rules="nextActionRule" :menu-props="{ maxHeight: 150, maxWidth: 10, fontSize: 5 }" />

        </v-col>
        <!-- <v-col cols="12" class="py-2">
          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600">Priority
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete :items="stageData" v-model="formData.priority" item-title="display" item-value="value"
            :disabled="showReadOnly" :placeholder="'Priority'" :rules="stageRule"
            :menu-props="{ maxHeight: 150, maxWidth: 10, fontSize: 5 }"
            :error-messages="validate && !formData.priority ? ['Priority  is required'] : []" />
        </v-col> -->
        <!-- <v-col cols="12" class="py-2">
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Due Date</v-label>
          <DatePicker :showReadOnly="showReadOnly" :data="formData.date" :placeholder="'Date'"
            @selectDate="selectDate($event)" :disabled="showReadOnly" />
          <TextFieldComponent class="date-picker" density="compact" placeholder="Due Date" type="datetime-local" showtime
            hoursFormat="24" v-model="formData.end_date" :rules="dueDateRule" :min="currentDateTime()" />
          <DatePicker :showReadOnly="showReadOnly" :data="formData.date" :placeholder="'Date'"
            @selectDate="selectDate($event)" :disabled="showReadOnly" /> 
        </v-col> -->

        <!-- <v-col cols="12" class="py-2" v-if="selectedProject && selectedProject.category_required == 1">

          <v-label class="pb-1" style="font-size: 12px; color: #272727; font-weight: 600">Skill
            <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete :items="categoryData" :placeholder="'Skill'" @update:modelValue="selectCategory"
            :item-title="'name'" :disabled="showReadOnly" :item-value="'id'" v-model="formData.category_id"
            :rules="categoryRule" :menu-props="{ maxHeight: 150, maxWidth: 10, fontSize: 5 }"
            :error-messages="validate && !formData.category_id ? ['Category is required'] : []" />
        </v-col> -->
        <v-col cols="12" class="pt-2 ">
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Skills
            <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
          <AutoComplete :items="skills" :placeholder="'Skill'" :item-title="'name'"
            :rules="selectedProject && selectedProject.skill == 1 ? skillRule : ''" :disabled="showReadOnly"
            v-model="formData.skill_id" :item-value="'id'" />
        </v-col>
        <v-col cols="12" class="py-2" >
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Quantity</v-label>
          <TextFieldComponent placeholder="Quantity" :disabled="showReadOnly" @keydown="prevent1($event)"
            v-model="formData.quantity" />
        </v-col>
        <!-- <v-col cols="12" class="py-2" >
          <v-checkbox  color="primary" v-model="formData.qa_required" label="QA Required"></v-checkbox>

        </v-col> -->
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn class="save" style="margin-top: -15%;" :loading="loading" @click="save()">Create Task</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable no-undef */

import AutoComplete from "@/components/AutoComplete.vue";
import BaseService from "@/api/service/BaseService";
import commonService from "@/api/service/common.service"
// import DatePicker from "@/components/DatePicker.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import Calendar from 'primevue/calendar'
import moment from 'moment'
;

export default {
  name: "issue",

  components: {
    AutoComplete,
    // DatePicker,
    TextFieldComponent

  },
  data() {
    return {
      tab: null,
      selectedLabel: null,
      isClicked: true,
      isClicked1: false,
      isClicked2: false,
      isClicked3: false,
      showAll:false,
      loading: false,
      items: [
        {
          name: "Logout"
        }
      ],
      skills: [],
      validate: false,
      showsave: true,
      selectedProject: null,
      roman: false, // Set the initial value of roman to false
      showSnackbar: false, // Controls the visibility of the snackbar
      snackbarTimeout: 3000, // Snackbar timeout in milliseconds
      snackbarColor: 'error', // Color of the snackbar (e.g., 'error', 'success', 'info')
      showNotification: false,
      taskGroupData: {},
      attachmentData: [],
      stageData: [
        {
          "value": "high",
          "display": "High"
        },
        {
          "value": "low",
          "display": "Low"
        },
        {
          "value": "medium",
          "display": "Medium"
        },
      ],
      userData: [],
      formData: {
        base: [],
        contentType: [],
        docName: [],
        priority: 'low'
      },
      workSpaceData: [],
      accountData: [],
      projectData: [],
      taskData: [],
      subject: [],
      categoryData: [],
      subCategoryData: [],
      issueTypeData: [],
      docData: [],
      valueFetched: false
    };
  },
  async created() {
    // this.getStageDate();
    // let userData =JSON.parse(localStorage.getItem('_USER_DETAILS_'))
    // this.formData.next_action =userData.id
   console.log(moment(new Date()).format('dddd'),"oooooooooooooooooooooooooooorrrrrrrrrrrrrrrrrrrr")
    this.formData.quantity = 1
    let wsDefault = localStorage.getItem("_WS_DEFAULTS_")
    this.wsDetails = wsDefault ? JSON.parse(wsDefault) : null
    this.showAll =this.wsDetails && this.wsDetails.taskgroup && this.wsDetails.taskgroup.id?false:true
    this.getTaskGroupData()
    await this.getMessageId();
    this.getUserData();
    this.getSubCategoryData();
    this.getTaskData();
    this.getCategoryData();
    this.getIssueTypeData();
    this.getWorkSpaceData()
    this.getSkills()

  },

  methods: {
    toggleButtonStates(clickedButton) {
      this.formData.priority = clickedButton === 'isClicked' ? 'low' : clickedButton === 'isClicked1' ? 'medium' :clickedButton === 'isClicked2' ? 'high':'critical'
      this.isClicked = clickedButton === 'isClicked';
      this.isClicked1 = clickedButton === 'isClicked1';
      this.isClicked2 = clickedButton === 'isClicked2';
      this.isClicked3 = clickedButton === 'isClicked3';
    },
    async getTaskGroupData() {
      if (this.wsDetails && this.wsDetails.taskgroup && this.wsDetails.taskgroup) {
        try {
          this.taskGroupData = this.wsDetails.taskgroup
          // console.log(this.taskGroupData,"taskGroupData")
          this.formData.category_id = this.taskGroupData && this.taskGroupData.category_id ? this.taskGroupData.category_id : null
          this.formData.sub_category_id = this.taskGroupData && this.taskGroupData.sub_category_id ? this.taskGroupData.sub_category_id : null
          this.formData.task_id = this.taskGroupData && this.taskGroupData.task_id ? this.taskGroupData.task_id : null
          this.formData.issue_type_id = this.taskGroupData && this.taskGroupData.issue_type_id ? this.taskGroupData.issue_type_id : null
        }
        catch (e) {
          console.log(e)
          this.taskGroupData = {}
        }
      }
    },
    prevent1(e) {
      if (!/^\d*\.?\d{0,2}$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    currentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    changeWorkSpace($event) {
      // this.valueFetched=false;
      this.formData.work_space_id = $event;
      this.formData.project_id = [];
      this.formData.account_id = [];
      this.$forceUpdate()
      // this.formData.project_id = null
      // this.formData.account_id = null
      if (this.formData.work_space_id) {
        this.getProjectData()
        this.getAccountData()
      }
    },
    selectCategory($event) {
      this.formData.category_id = $event;
      this.formData.sub_category_id = [];
      if (this.formData.category_id) {
        this.getSubCategoryData();
      }
    },
    selectDate($event) {
      this.formData.end_date = $event
    },

    async getAccountData() {
      this.valueFetched = true;
      if (this.formData.work_space_id) {
        try {
          let res = await BaseService.getAccountByIssue({ work_space_id: this.formData.work_space_id,active:1 });
          this.accountData = res.length > 0 ? res : [];
        }
        catch (e) {
          console.log(e)
          // this.accountData = res.length > 0 ? res : [];

        }
      }
    },

    async getWorkSpaceData() {
      try {
        let res = await BaseService.workSpace({active:1 });
        this.workSpaceData = res.length > 0 ? res : [];
      }
      catch (e) {
        console.log(e)
        this.workSpaceData = res.length > 0 ? res : [];

      }
    },

    async getSkills() {
      try {
        let res = await BaseService.findAllSkill({active:1});
        this.skills = res.length > 0 ? await commonService.getSort(res, 'name', 'asc') : [];
        let fData = this.skills.filter(k => k.code.toUpperCase() == 'BEGINNER')
        this.formData.skill_id = fData && fData.length > 0 ? fData[0].id : null

        BEGINNER
      }
      catch (e) {
        this.skill = []
      }
    },

    async getTaskData() {
      let res = await BaseService.task({ task_type: "production",active:1});
      this.taskData = res.length > 0 ? res : [];
    },

    async getProjectData() {
      this.valueFetched = true;
      if (this.formData.work_space_id) {
        let res = await BaseService.getWorkSpaceProject({ work_space_id: this.formData.work_space_id });
        this.projectData = res.length > 0 ? res : [];
      }
    },

    async getUserData() {
      try {
        let res = await BaseService.getNextAction(
          {
            'work_space_id': this.wsDetails.workspace.id,
            'account_id': this.wsDetails.account.id,
            'project_id': this.wsDetails.project.id,
             'active':1 
          }
        );
        this.userData = res.length > 0 ? res : [];
      }
      catch (e) {
        console.log(e)
      }
    },

    async getCategoryData() {

      let $projects = await BaseService.findAll({ "id": this.wsDetails.project.id,active:1  })
      this.selectedProject = $projects && $projects.length > 0 ? $projects[0] : {}
      let $categoryId =$projects &&  $projects[0].category_id?$projects[0].category_id:null
      let res = await BaseService.categoryById({ "categoryId": ($categoryId ? $categoryId : '[]') });
      this.categoryData = res.length > 0 ? res : [];
    },

    async getSubCategoryData() {
      if (this.formData.category_id) {
        let res = await BaseService.subCategory({
          category_id: this.formData.category_id,
          active:1
        });
        this.subCategoryData = res.length > 0 ? res : [];
      }
    },

    async getIssueTypeData() {
      try {
        let res = await BaseService.isseType({active:1 });
        this.issueTypeData = res.length > 0 ? res : [];
        let fData = this.issueTypeData.filter(k => k.code.toLowerCase() == 'd2d')
        this.formData.issue_type_id = fData && fData.length > 0 ? fData[0].id : null
      }
      catch (e) {
        this.issueTypeData = []
      }
    },
    // async getStageDate() {
    //   try {
    //     let res = await BaseService.stage();
    //     this.stageData = res;
    //   } catch (e) {
    //     this.stageData = [];
    //     console.log(e);
    //   }
    // },

    async getMessageId() {
      const item = Office.context.mailbox.item;
      let issueData = await BaseService.allIssues({ mail_box_id: item.itemId,active:1  });
      console.log(issueData, "get Issue Data")
    },

    async save() { 
   
      if (this.validate) {
        this.loading = true
      }
      let $wsDefaults = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
      let item =Office.context.mailbox.item
      this.loading = true
      console.log(item,"ppppppppppppppp")
      let mailDate =moment(Office.context.mailbox.item.dateTimeCreated)
      let details ={
        from:item && item.sender?item.sender:null,
        to:item && item.to?item.to:null,
        cc:item && item.cc?item.cc:null,
        bcc:item && item.bcc?item.bcc:null,
        attachments:item && item.attachments?item.attachments:null,
        received_date:mailDate
      }
      this.formData.mail_details=details?JSON.stringify(details):null
      this.formData.mail_date =mailDate.format("YYYY-MM-DD HH:mm:ss");
      let day =moment(this.formData.mail_date).format('dddd')
      let hour =day.toLowerCase()=='friday'|| day.toLowerCase()=='saturday'?72:day.toLowerCase()=='sunday'?48:24
      this.formData.end_date =moment(this.formData.mail_date).add(hour,'hour').format("YYYY-MM-DD HH:mm:ss"); 
      this.formData.account_id = $wsDefaults && $wsDefaults.account && $wsDefaults.account.id ? $wsDefaults.account.id : null
      this.formData.project_id = $wsDefaults && $wsDefaults.project && $wsDefaults.project.id ? $wsDefaults.project.id : null
      this.formData.work_space_id = $wsDefaults && $wsDefaults.project && $wsDefaults.workspace.id ? $wsDefaults.workspace.id : null

      if (!this.formData.subject) {
        const item = Office.context.mailbox.item;
        this.formData.mail_box_id = item.itemId;
        this.formData.name = item.subject
        item.body.getAsync(
          Office.CoercionType.Text,
          { asyncContext: {} },
          (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
              this.formData.description = result.value;
            } else {
              console.error(
                "Failed to get message body: " + result.error.message
              );
            }
          }
        );
      }
      if (this.formData.project_id && this.formData.account_id && this.formData.task_id && this.formData.end_date &&
        this.formData.priority) {
        const item = Office.context.mailbox.item;

        /* get mail subject */

        this.formData.name = item.subject;
        // this.formData.messageId = item.itemId;


        /* get mail body */

        item.body.getAsync(
          Office.CoercionType.Html,
          { asyncContext: {} },
          (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
              this.formData.description = result.value;
              this.getAttachement();


            } else {
              console.error("Failed to get message body: " + result.error.message);
            }
          }
        );

      } else {
        this.validate = true
      }
    },
    async getAttachement() {
      let item = Office.context.mailbox.item;
      let docData = [];
      const promises = [];
      if (item.attachments.length) {
        for (var i = 0; i < item.attachments.length; i++) {
          let $obj = {}
          $obj.file_name = item.attachments[i].name
          $obj.doc_name = item.attachments[i].name
          $obj.name = item.attachments[i].name
          $obj.file_type = item.attachments[i].contentType
          $obj.key_type = 'IS'
          await promises.push(
            new Promise((resolve) => {
              item.getAttachmentContentAsync(
                item.attachments[i].id,
                function (result) {
                  if (result.status === Office.AsyncResultStatus.Succeeded) {
                    if (result.value && result.value.content) {
                      $obj.data = result.value.content
                      docData.push($obj)

                      resolve();
                    } else {
                      console.error(
                        "Attachment content or content property is missing."
                      );
                      resolve();
                    }
                  } else {
                    console.error(
                      "Failed to retrieve attachment content: " +
                      result.error.message
                    );
                    resolve();
                  }
                }
              );
            })
          )
        }
      }
      await Promise.all(promises);
      if (this.showSnackbar === false) {
        // console.log("hello skill data")
        let docRes = await BaseService.bulkUpload({ docData: docData })
        let docId = JSON.parse(JSON.stringify(docRes.data.data))
        this.formData.attachment = JSON.stringify(docRes.data.data)
        this.formData.mail_date =await commonService.changeTheDateToUtc(this.formData.mail_date, Intl.DateTimeFormat().resolvedOptions().timeZone)
        this.formData.end_date = await commonService.changeTheDateToUtc(this.formData.end_date, Intl.DateTimeFormat().resolvedOptions().timeZone);
        // console.log(this.formData, "hello skill data")
        // this.formData.qa_required =this.formData.qa_required==true?1:0
        // console.log(this.formData, 'formDataaaaa')
        let response = await BaseService.createIssue(this.formData)  
        // console.log("start category")
        // console.log(Office.MailboxEnums.CategoryColor.Preset0)
        let categoryData =Office.context.mailbox.item
        // let category = [
        //               {
        //                 displayName: "TestCategory",
        //                 color: Office.MailboxEnums.CategoryColor.Preset0
        //               }
        //             ];
        categoryData.categories.addAsync(['Moved to synergy'], function(asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            console.log(`Successfully assigned category to item.`);
          } else {
            console.log("categories.addAsync call failed with error: " + asyncResult.error.message);
          }
        });
        // console.log(response, "hello response data")
        if (response.data.data.id) {
          for (let i = 0; i < docId.length; i++) {
            let updateData = {
              id: docId[i],
              key_id: response.data.data.id
            }
            await BaseService.updateDocument(updateData)
          }
          this.$emit("output", "Issue Created Successfully")
        }
      }
    },
    cancel() {
      this.formData = []

    },
    logout() {
      localStorage.removeItem("TOKEN");
      window.location.reload();
    }
  },
};

</script>
<style scoped>
.date-picker input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  color: red;
  /* Change the color as desired */
}

>>>.p-inputtext:enabled:focus-within {
  border-color: #6366F1;
  background: #fff;
}

>>>.p-fluid .p-calendar .p-inputtext {
  /* width: 1%; */
  background: #ECECEC;
}

>>>.p-fluid .p-calendar {
  display: flex;
  width: 100% !important;
  height: 40px !important;
}

>>>.v-col {
  margin-top: -20px;
  margin-left: 5px;
}

.stepLast {
  margin-top: 15px;
  /* margin-left: 60px; */

}

.stepOne {
  margin-top: 5px;
  margin-left: 5px;

}

.save {
  grid-area: content;
  width: 50px;
  right: 10px;
  justify-content: center;
  white-space: nowrap;
  color: white;
  background-color: "#3c5aaae";
}

.custom-btn {
  height: 30px;
  /* Adjust the height as needed */
  width: 50px;
  /* Adjust the width as needed */
}

.picker {
  width: 190px;
  /* height: 100px; */
}

.calander {
  height: 33px;
  width: 195px;
}

>>>.p-fluid .p-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 31px;
  width: 191px
}

>>>.v-list-item {
  font-size: 50px;
}

>>>.v-list-item--density-default.v-list-item--one-line {
  min-height: 0px;
  padding-top: -11px;
  padding-bottom: 1px;

}

>>>.v-slide-group__content {
  justify-content: center;
}

>>>.v-list-item {
  font-size: 10px !important;
}

>>>.v-list-item-title {
  font-size: 13px !important;
}

/* >>>.v-list-item--density-default.v-list-item--one-line {
  min-height: 28px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}  */

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Adjust as needed */
}

.v-input--density-default .v-field--variant-solo,
.v-input--density-default .v-field--variant-solo-inverted,
.v-input--density-default .v-field--variant-solo-filled,
.v-input--density-default .v-field--variant-filled {
  v-input-control-height: 22px;
  v-field-padding-bottom: 5px;
}
.pButton{
  scale: 0.8;
    padding: 4px 8px;
    width: fit-content;
    height: fit-content;
    border: 0.5px solid;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
}
.low {
  color: black;
  caret-color: rgb(0, 255, 10);
  background-color: rgb(133 249 138);

}

.medium {
  color: black;
  caret-color: #ffeb3b;
  background-color: #ffeb3b;

}

.high {
  color: white;
  caret-color: #FF5252;
  background-color: #FF5252;

}
.critical {
  color: white;
  caret-color:#D50000;
  background-color:#D50000;

}</style>
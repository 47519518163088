<template>
  <div class="container">
    <div class="center">
      <v-img  class="logo-svg" src="@/assets/SynergyLogo.svg"></v-img>
      <h1 class="synergy-text">Synergy</h1>    
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.logo-svg {
  width: 100px;
  height: 100px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1258AB; /* Set the desired background color */
}

.center {
  text-align: center;
}
.synergy-text {
  color: white; /* Set the text color to white */
}

</style>


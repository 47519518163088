import ApiService from './apiService';
const querystring = require('querystring')
import apiConfig from '../config/config';

var ProjectService = {

    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "projects"
            $options.baseUrl=apiConfig.baseUrl
            $options.method = "get"

            if ($data) {
                $options.url = "projects?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    getAccountByEmail: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAccountByEmail"
            $options.baseUrl=apiConfig.baseUrl
            $options.method = "get"

            if ($data) {
                $options.url = "getAccountByEmail?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    

    categoryById: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "categoryById"
            $options.method = "get"

            if ($data) {
                $options.url = "categoryById?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getIssuesForWorkspace: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "showWorkSpaces"
            $options.method = "get"

            if ($data) {
                $options.url = "showWorkSpaces?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getProjects:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "projectsOnWorkspace"
            $options.method = "get"

            if ($data) {
                $options.url = "projectsOnWorkspace?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getAccounts:function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "accountsOnWorkspace"
            $options.method = "get"

            if ($data) {
                $options.url = "accountsOnWorkspace?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });
    },
    task: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "tasks"
            $options.method = "get"

            if ($data) {
                $options.url = "tasks?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test 1');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    workSpace: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getWorkSpaceByUser"
            $options.method = "get"

            if ($data) {
                $options.url = "getWorkSpaceByUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getAccountByIssue: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getAccountByIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "getAccountByIssue?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    imageProfile: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "imageProfile"
            $options.method = "get"

            if ($data) {
                $options.url = "imageProfile?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    
    findOneUser: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "users/" + $data.id
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getWorkSpaceProject: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getWSProject"
            $options.method = "get"

            if ($data) {
                $options.url = "getWSProject?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    isseType: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issuetypes"
            $options.method = "get"

            if ($data) {
                $options.url = "issuetypes?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    category: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "categories"
            $options.method = "get"

            if ($data) {
                $options.url = "categories?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    findAllSkill: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "risks"
            $options.method = "get"

            if ($data) {
                $options.url = "risks?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    subCategory: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "subcategories"
            $options.method = "get"

            if ($data) {
                $options.url = "subcategories?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    

    getUserByWorkSpace: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "workSpceUser"
            $options.method = "get"

            if ($data) {
                $options.url = "workSpceUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    nextAction: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "users"
            $options.method = "get"

            if ($data) {
                $options.url = "users?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    stage: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "stages"
            $options.method = "get"

            if ($data) {
                $options.url = "stages?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    issueList: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issues"
            $options.method = "get"

            if ($data) {
                $options.url = "issues?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    document: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "documents";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    
    updateDocument: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "documents/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    

    getNextAction: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getNextAction"
            $options.method = "get"

            if ($data) {
                $options.url = "getNextAction?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    taskGroup: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "taskgroup"
            $options.baseUrl=apiConfig.baseUrl
            $options.method = "get"

            if ($data) {
                $options.url = "taskgroup?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },



    allIssues: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "allIssues"
            $options.method = "get"

            if ($data) {
                $options.url = "allIssues?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getIssueAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "issues"
            $options.method = "get"

            if ($data) {
                $options.url = "issues?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    createIssue: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "issues";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    bulkUpload: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "bulkUpload";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    attachment: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "getattachment";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    }

}
export default ProjectService;
<template>

  <v-row class="body" >
    <v-col align="center">

      <v-form @submit.prevent="login"  ref="form">
        <strong style="font-size: 30px; color: #fff">Synergy</strong>
        <v-card class="card mt-5 pa-8 " style="width:300px; height: 400px;" >
          <v-card-title class="LoginTitle">Login to Synergy</v-card-title>
          <div class="error" v-if="error">Invalid Username And Password</div>

          <v-col class="pa-0 pt-2">
            <TextFieldComponent class="mt-4" density="compact" placeholder="Your Email" type="email"
              append-inner-icon="mdi-account-outline" :rules="[rules.userRule, rules.emailValidation]" v-model="email"
              variant="outlined">
            </TextFieldComponent>
          </v-col>
          <v-col class="pa-0 pt-6">
            <TextFieldComponent density="compact" @click:append-inner="showPassword = !showPassword"
              placeholder="Password" :rules="passwordRule" v-model="password" variant="outlined"
              v-bind:type="[showPassword ? 'text' : 'password']"
              :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'">
              <!-- <span class="visionIcon"> -->
              <!-- <v-icon @click="showPassword = !showPassword">{{
                showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
              }}</v-icon> -->
              <!-- </span> -->
            </TextFieldComponent>
          </v-col>
          <v-col class="pa-0" cols="12" align="center">
            <p style="color: red"></p>
          </v-col>
          <v-col class="pa-0 pt-6" cols="12" align="center">
            <v-btn block color="indigo" type="submit" size="large" :disabled="loading" :loading="loading"
              class="text-none mb-4">Login</v-btn>
          </v-col>

          <v-row no-gutters class="mt-5">
            <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
            <v-col align="center">OR</v-col>
            <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" align="center" class="mt-2">
              <!-- <v-btn variant="plain" to="/forgetpassword">Forgot your password ?</v-btn> -->
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-1">
            <v-col class="pt-1" align="center">Trouble Signing in</v-col>
            <!-- <v-col align="end">
            <v-btn color="indigo" variant="plain" to="/signup">Sign up</v-btn>
          </v-col> -->
          </v-row>
        </v-card>
      </v-form>
    </v-col>
  </v-row>

</template>

<script>
// import UserService from "@/api/service/UserService";
import TextFieldComponent from "./TextFieldComponent.vue";
import userService from "../api/service/UserService.js";
import config from '../api/config/config.js'


export default {
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      loading:false,
      error: false,
      host: window.location.host,
      message: null,
      redirecturl: null,
      showPassword: false,
      password: null,
      emailValidation: "",
      passwordRule: [(value) => !!value || "Password is Required."],
      email: null,
      rules: {
        userRule: (value) => !!value || "Email is Required.",
      },

      appData: JSON.parse(localStorage.getItem("AppDetail")),
    };
  },
  // created() {
  //   this.redirecturl = this.$route.query.redirectUrl
  // },
  methods: {
    async login() {
      if (this.email && this.password) {
        try {
          this.loading=true
          let response = await userService.fireBaseLogin({ email: this.email, password: this.password,appId:config.appId  })

          if (response.token) {
            try{
            let authResponse = await userService.authenticate(response.token)
            let token = authResponse.data.desks[0].token
            localStorage.setItem('TOKEN',token)
            localStorage.setItem('_USER_DETAILS_',JSON.stringify(authResponse.data.userData))
            window.location=window.location.href
            // }
          }
          catch(e){
          this.error = true
          this.loading=false
            console.log(e)
          }
          }
        } catch (e) {
          this.error = true
          this.loading=false
        }
      }
      else {
        this.error = true
      }
      

    },
  },
};
</script>
<style>
.error {
  color: red;
  font-size: 15px;
}
</style>

<template>
    <img @click="remove" src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/Issues_gray.svg "
        style="width:15px;position: absolute;padding-top: 19px;padding-right: 2px;cursor: pointer;" />
    <span style="position: absolute;
    padding-top: 14px;
    left: 15px;">/</span>
    <v-breadcrumbs v-if="isOkToShow()" :items="section" bg-color="#ffffff" class="ml-2">
        <template v-slot:title="{ item }">
            <span @click="Openmenu(item)" class="name" :style="item.bold ? 'font-weight:800' : 'font-weight:400'">
                {{ item.title }}
            </span>
        </template>
    </v-breadcrumbs>
</template>

<script>

export default {
    props: ["reload"],
    data() {
        return {
            section: []
        }
    },
    computed: {
        isRefreshed() {
            return true
        }
    },
    watch: {
        reload() {
            this.refreshSection()
        },
        isRefreshed() {
            this.refreshSection()
        }
    },

    created() {
        this.refreshSection()
    },
    methods: {
        remove() {
            let $wsDetails = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
            delete $wsDetails.workspace
            delete $wsDetails.project
            delete $wsDetails.account
            delete $wsDetails.taskgroup
            localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDetails))
            this.refreshSection()
            this.$emit("refreshData", { type: "workspaces" })
        },
        Openmenu($event) {
            console.log($event, "oooooo")
            let $wsDetails = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
            if ($event.type == 'workspaces') {
                let $key = Object.keys($wsDetails)
                if ($key.length == 1) {
                    delete $wsDetails.workspace
                }
                delete $wsDetails.project
                delete $wsDetails.account
                delete $wsDetails.taskgroup
            }
            else if ($event.type == 'account') {
                delete $wsDetails.project
                delete $wsDetails.taskgroup
            }
            else if ($event.type == 'project') {
                delete $wsDetails.taskgroup
            }
            localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDetails))
            this.refreshSection()
            this.$emit("refreshData", $event)
        },
        refreshSection() {
            let $wsDetails = localStorage.getItem("_WS_DEFAULTS_")
            if ($wsDetails) {
                $wsDetails = JSON.parse($wsDetails)
                this.section = []
                if ($wsDetails.workspace) {
                    this.section.push({
                        title: $wsDetails.workspace.name,
                        disabled: false,
                        type: 'workspaces'
                    })
                }
                if ($wsDetails.account) {
                    this.section.push({
                        title: $wsDetails.account.name,
                        disabled: false,
                        type: 'account'
                    })
                }
                if ($wsDetails.project) {
                    this.section.push({
                        title: $wsDetails.project.name,
                        disabled: false,
                        type: 'project'
                    })
                }
                // if($wsDetails.taskgroup) {
                //     this.section.push({
                //         title: $wsDetails.taskgroup.name,
                //         disabled: false,
                //         type:'taskgroup'
                //     })
                // } 
                if ($wsDetails.category) {
                    this.section.push({
                        title: $wsDetails.category.name,
                        disabled: false,
                        href: '/issueList?workSpaceId=' + $wsDetails.workspace.id + "&accountId=" + $wsDetails.account.id + "&projectId=" + $wsDetails.project.id + "&categoryId=" + $wsDetails.categoryId
                    })
                }
                if ($wsDetails.issue) {
                    this.section.push({
                        title: $wsDetails.issue.name,
                        disabled: false,
                        href: '/issueView?id=' + $wsDetails.issue.id,
                        bold: true
                    })
                }
                if ($wsDetails.child_issue) {
                    this.section.push({
                        title: $wsDetails.issue.name,
                        disabled: false,
                        href: '/issueView?id=' + $wsDetails.issue.id,
                        bold: true
                    })
                }
            }
            this.$nextTick(() => {
                //  this.$store.commit("SET_REFRESH_BREADCRUMP",false)
            });

        },
        isOkToShow() {
            return true;
        }
    }
}
</script>
<style>
.name {
    cursor: pointer;
    font-size: 11px;
    color: #636363;
}
</style>

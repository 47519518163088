<template>
    <div>
        <div 
        style="padding: 4px;
        font-size: 10px;
        font-weight: 400;
        background: rgba(224, 224, 224, 0.137);
        color: rgb(80 78 78);
        border: 1px solid rgb(149 149 149);
        border-radius: 6px;
        margin: 6px;">
        <b>Mail Box</b> {{ getProfile}} 
         <v-icon @click="showDetails=!showDetails"  size="18px" class="pl-3 mdi mdi-information" style="cursor: pointer;" /><br/>
        <span v-if="showDetails"><b>Conversation #</b> {{ getMailConversationId }}<br/>
        <b>Item id #</b> {{ getMailItemId }}<br/>
        <b>Rest #</b> {{ getMailRestId }}<br/></span>
</div>

        <DefaultSelection v-if="defaultSelection != 'none' && !showMessage" :defaultSelection="defaultSelection" 
            @itemSelected="itemSelected" />
        <Stepper v-if="defaultSelection == 'none' && !showMessage" @output="setMessage" />
        <Message v-if="showMessage" :message="messageData" :data="issueData" />
    </div>
</template>
<script>
/* eslint-disable no-undef */
import DefaultSelection from './DefaultSelection.vue'
import Stepper from "@/components/Stepper.vue"
import Message from "@/components/Message.vue"
import BaseService from "@/api/service/BaseService";
import moment from 'moment'
export default {
    props: ["refreshData"],
    components: {
        DefaultSelection,
        Stepper,
        Message
    },
    data() {
        return {
            showDetails:false,
            profileUpdate:false,
            defaultSelection: null,
            messageData: null,
            showMessage: false,
            issueData: null,
            defaultData:null,
            accountByEmailData:null

        }
    },
    // async created() {
    //     await this.getMessageId();
    //     this.defaultSelection = "workspaces",
    //     this.isOkToCreate()
    // },
    computed:{
        
        getMailItemId() {
            return Office.context.mailbox.item.itemId
        },
        getMailConversationId() {
            return Office.context.mailbox.item.conversationId
        },
        getMailRestId() {
            // Convert to an item ID for API v2.0.
            return Office.context.mailbox.convertToRestId(
                Office.context.mailbox.item.itemId,
                Office.MailboxEnums.RestVersion.v2_0
            );
        },
        getProfile() {
            return Office.context.mailbox.userProfile.emailAddress
        }
    },
    watch: {
        async getProfile() {
            await this.getAccountByEmail()  
        },
        refreshData() {
            if (this.refreshData) {
                this.isOkToCreate()
                this.$emit('refreshed')
            }
        }

    },
    mounted() {

    },
    async updated() {
       

    },
    async created(){  
              await this.getMessageId();
        this.defaultSelection = "workspaces",
        this.isOkToCreate()
     this.defaultData = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
     await this.getAccountByEmail()  
    },
    methods: {
        moment,
        async getAccountByEmail(){
         try{
            this.profileUpdate=true
            console.log(Office.context.mailbox.userProfile,"getting email data")
            let res = await BaseService.getAccountByEmail({email:Office.context.mailbox.userProfile.emailAddress})
            this.accountByEmailData =res && res.length>0?res[0]:null
            if(this.accountByEmailData && this.accountByEmailData.id && this.defaultData && this.defaultData.account && this.accountByEmailData.id!=this.defaultData.account.id){     
                console.log(Office.context.mailbox.userProfile,"getting email data")       
                let obj ={
                        "code":this.accountByEmailData.code,
                         "id":this.accountByEmailData.id,
                         "name":this.accountByEmailData.name,
                    }
                    delete this.defaultData.account
                    delete this.defaultData.project
                    localStorage.setItem('_WS_DEFAULTS_', JSON.stringify(this.defaultData))
                    this.defaultSelection='account'
                    this.itemSelected(obj)
            }
            this.profileUpdate=false
         }
         catch(e){
            this.accountByEmailData =null
            console.log(e)
         }
        },

        itemSelected($item) {
            console.log($item,"getting the chan")
            let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")
            // console.log($item,'wsDefaults')
            if ($wsDefaults) {
                $wsDefaults = JSON.parse($wsDefaults)
            } else {
                $wsDefaults = {}
            }
            switch (this.defaultSelection) {
                case 'workspaces':
                    $wsDefaults.workspace = $item;
                    break;
                case 'project':
                    $wsDefaults.project = $item;
                    break;
                case 'account':
                    $wsDefaults.account = $item;
                    break;
                case 'taskgroup':
                    $wsDefaults.taskgroup = $item;
                    break;    
            }
            localStorage.setItem('_WS_DEFAULTS_', JSON.stringify($wsDefaults))
            this.$emit("reload", true)
            this.isOkToCreate()
        },
        setMessage($event) {
            this.showMessage = true
            this.messageData = $event
            this.getMessageId($event)
            this.$emit("created", true)
        },
        async getMessageId($msg) {
            // eslint-disable-next-line no-undef
            const item = Office.context.mailbox.item;
            let issueData = await BaseService.allIssues({ mail_box_id: item.itemId });
            console.log(issueData,"yyyyyyyyy")
            this.issueData = issueData[0]
            console.log(this.issueData,"pppppppppppppppppuuuiii")

            if (issueData.length > 0) {
                this.messageData = $msg?$msg:'Issue Already Created'
                this.showMessage = true
                this.$emit("created", true)
            }
            else {
                this.showMessage = false
            }
        },
        isOkToCreate() {
            let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_")

            if ($wsDefaults) {
                $wsDefaults = JSON.parse($wsDefaults)
                if (!$wsDefaults.workspace) {
                    this.defaultSelection = 'workspaces'
                }
                else if (!$wsDefaults.account) {
                    this.defaultSelection = 'account'
                }
                else if (!$wsDefaults.project) {
                    this.defaultSelection = 'project'
                }
                else if (!$wsDefaults.taskgroup) {
                    this.defaultSelection = 'taskgroup'
                }
                else {
                    this.defaultSelection = 'none'
                }
            } else {
                this.defaultSelection = 'workspaces'
                return
            }
        }
    }
}

</script>


<template>
   <v-text-field  class="placeholder-color" 
            @keydown.space="preventLeadingSpace" 
            v-focus
            density="compact" @keydown="handleKeydown"
            :disabled="showReadOnly" 
   color="#000000" variant="outlined">
  </v-text-field>
</template>
<script>

export default{
  props:['showReadOnly'],
  
  name:"textComponet",

  methods:{
  //     handleKeydown(event) {
  //   if (event.key === ' ') {
  //     event.preventDefault();
  //   } 
  // }
  preventLeadingSpace(e) {
    // only prevent the keypress if the value is blank
    if (!e.target.value) e.preventDefault();
    // otherwise, if the leading character is a space, remove all leading white-space
    else if (e.target.value[0]==' ') e.target.value = e.target.value.replace(/^\s*/, "");
  },  
  trimSpace(){
    this.category.code = this.category.code.trim()
  }, 
  }
}
</script>
<style scoped>



>>> .v-field__field .v-field__input {
  cursor: text;
  background-color:#ECECEC !important;
  font-size: 14px;
  color: #777777;
  border-radius: 8px !important;
  min-height: 0px !important;
}

>>> .v-field__field .v-field__input:focus-within {
  cursor: text;
  background-color:#fff !important;
  font-size: 14px;
  color: #000;
  border-radius: 8px !important;
}

>>> .v-field__field .v-field__input::placeholder {
  font-size:13px;
}

>>> .v-field--focused .v-field__input {
  background-color:#ffffff;
}
>>>  .v-field__input input:placeholder-shown {
  color:#000000 !important;
  font-size:12px;

}

>>> .v-field__outline {
  --v-field-border-width: 0px !important;
  border: 10px !important;
  --v-field-border-opacity: 0.38;
  align-items: stretch;
  contain: layout;
  display: flex;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 100%;
  border: 0px solid #D7D6D6 !important;
  border-radius: 8px !important;
}

>>> .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
  border: 0px solid #3C5AAA !important;
  box-shadow: 0 0 1px 2px #3C5AAA !important;
  

}

>>> .v-messages__message {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-left:0px;
  transition-duration: 150ms;
  text-align: left;
}

>>>.v-field.v-field--appended {
 
  cursor: text;
  background-color:#ECECEC !important;
  font-size: 14px;
  color: #777777;
  border-radius: 8px !important;
}

>>>.v-field.v-field--appended:focus-within {
 
 cursor: text;
 background-color:#ffffff !important;
 font-size: 14px;
 color: #777777;
 border-radius: 8px !important;
}
>>>.v-input__details {
  padding-inline-start: 2px !important;
   
}
>>>.v-field--disabled {
  pointer-events: none;
  opacity: 0.8 !important;
}
</style>
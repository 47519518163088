const apiConfig = {
    // "baseUrl":"http://localhost:3010",
    "baseUrl":"https://api-axodesk.tiburaglobal.com:8081",
    "gatWayUrl":"https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
    "apiKey": "apiKey",
    "appId": "b0dc92bf-3710-4e11-a87d-4d72585cd281",
    "secrect": "secrect",
    "webUrl":"https://axodesk.tiburaglobal.com/"
}
export default apiConfig;



import axios from 'axios';
var headers = {
    'Content-Type': 'application/json',
}
const ApiService = {
    getHeaders() {
        return {
            'Content-Type': 'application/json'
        }
    },
    request: async function ($options) {
        return new Promise(function (resolve, reject) {
            $options.url = $options.baseUrl + "/" + $options.url;
            console.log($options)
            if ($options.method.toUpperCase() == 'POST') {

                let $h
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: this.getHeaders() }
                } else {
                    $h = { headers: headers }
                }
                axios.post($options.url, $options.data, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                        console.log(error);

                    });
            } else if ($options.method.toUpperCase()  == 'DELETE') {
                axios.delete($options.url, { "headers": this.getHeaders() })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);

                    });
            } else if ($options.method.toUpperCase()  == 'PUT') {
                axios.put($options.url, $options.data, { headers: this.getHeaders() })
                    .then(response => {
                        //console.log(response);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                        console.log(error);

                    });
            } else {
                var $h = {}
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: this.getHeaders() }
                } else {
                    $h = { headers: headers }
                }
                axios.get($options.url, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(async error => {
                        reject(error)
                        console.log(error);
                    });
            }


        });


    }

}

export default ApiService
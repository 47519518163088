<template>
  <v-app>
    <v-main>
      <v-app-bar v-if="token">
        <v-img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/images/SynergyTextLogo.png" cover />
        <v-btn class="profile" variant="text" id="menu-activator"><v-icon style="color:#1258ab;margin-top: -10px;"
            size="25">mdi-account-circle-outline</v-icon>
          <v-tooltip activator="parent" location="bottom">Profile</v-tooltip>
        </v-btn>
        <v-menu activator="#menu-activator">
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index" :value="index">
              <v-list-item-title @click="logout()">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <BreadCrumps v-if="token && showBreadCrumps" :reload="reload" @refreshData="refreshData($event)" />
      <div v-if="!token">
        <Flash v-if="showFlash" />
        <Login v-if="!token" />
      </div>
      <IssueView v-if="token" @reload="reloadBreadCrumps" @created="createIssue()" :refreshData="refreshDatas"
        @refreshed="refreshed()" />

    </v-main>
  </v-app>
</template>

<script>
import Login from '@/components/Login.vue'
// import Stepper from '@/components/Stepper.vue'
import Flash from '@/components/flash.vue'
import BreadCrumps from '@/components/BreadCrumps.vue'
import IssueView from "@/components/IssueView.vue"


export default {
  name: 'App',

  components: {
    Login,
    BreadCrumps,
    IssueView,
    Flash
  },

  data() {
    return {
      token: localStorage.getItem('TOKEN'),
      showFlash: true,
      showBreadCrumps: true,
      reload: true,
      items: [
        {
          name: "Logout"
        }
      ],
      refreshDatas: false
    };
  },

  mounted() {
    if (!this.token) {
      setTimeout(() => {
        this.showFlash = false;
      }, 1000);
    }
  },
  methods: {

    logout() {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("_WS_DEFAULTS_");
      window.location.reload();
    },
    refreshed() {
      this.refreshDatas = false
    },
    reloadBreadCrumps() {
      this.reload = !this.reload
    },
    refreshData($event) {
      console.log($event)
      this.refreshDatas = true
    },
    createIssue($event) {
      this.showBreadCrumps = $event ? true : false
    }
  }
};
</script>

<style lang="scss">
@import '../src/scss/style.scss';

.v-img__img--cover {
  margin: 10px;
  width: 150px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
}
</style>

<template>
    <div>
    <CreateIssue @reload="$emit('reload',true)" @created="$emit('created',true)" :refreshData="refreshData" @refreshed="$emit('refreshed')" />
    </div>
</template>
<script>
import CreateIssue from './CreateIssue.vue'
export default {
    props:["refreshData"],
    components:{
        CreateIssue
    },
    data() {
        return {

        }
    }
}
</script>
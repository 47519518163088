
<template>
   
    <v-list dense>
        <v-list-subheader>
            <b>SELECT {{ defaultSelection ? defaultSelection.toUpperCase() : '' }}</b>
        </v-list-subheader>
        <v-divider />
        <TextFieldComponent density="compact" v-model="search" append-inner-icon="mdi-magnify" class="pa-4" label="Search"
            single-line hide-details @input="searchData()" />
        <v-list-item v-for="item in options" :key="item" id="list-Item" @click="$emit('itemSelected', item);setLast()">
            <v-list-item-title  class="item">
                {{ item.name }} 
            </v-list-item-title>
        </v-list-item>
    </v-list>
    <div v-if="options.length==0"  class="pa-4 item">There is no {{defaultSelection ? defaultSelection :''}} for selected {{ lastSelection }}</div>
    <!-- <div v-if="defaultSelection == 'workspaces' && !options" class="pa-4">There is no {{defaultSelection ? defaultSelection :''}} </div> -->
    <div class="pt-12 pr-4"  style="margin-top:-15%;display: flex;justify-content: end;" v-if="defaultSelection == 'taskgroup'">
    <v-btn style="display: grid;place-items: center;    width: 50px;
    height: 28px;" variant="outlined" color="primary" :loading="loading" @click="$emit('itemSelected',options)">Skip</v-btn>
    </div>
</template>
<script>
/* eslint-disable no-undef */
import BaseService from '@/api/service/BaseService'
import TextFieldComponent from "@/components/TextFieldComponent.vue";
export default {

    components: {
        TextFieldComponent
    }, props: ['defaultSelection'],
    data() {
        return {
            defaultData: {},
            lastSelection:{},
            options: [],
            search: null,
            accountByEmailData:null
        }
    },
    async created() {
        this.defaultData = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
        this.setData()
        console.log(this.options,'optionsss')
        await this.getAccountByEmail()

    },
    watch: {
        defaultSelection() {
            if (this.defaultSelection) {
                console.log(this.defaultSelection, 'check22222')
                this.setData()
            }
        }

    },
    methods: {
        async getAccountByEmail(){
         try{
            // console.log(Office.context.mailbox.userProfile,"getting email data")
            let res = await BaseService.getAccountByEmail({email:Office.context.mailbox.userProfile.emailAddress})
            this.accountByEmailData =res && res.length>0?res[0]:null
         }
         catch(e){
            this.accountByEmailData =null
            console.log(e)
         }
        },
        setLast(){
            this.lastSelection = this.defaultSelection
            console.log(this.lastSelection,' this.lastSelection')
        },
        setData() {
            this.search = null
            switch (this.defaultSelection) {
                case 'workspaces':
                    this.getWorkSpaceData()
                    break;
                case 'project':
                    this.getProjectData()
                    break;
                case 'account':
                    this.getAccountData()
                    break;
                case 'taskgroup':
                    this.getTaskGroupData()
                    break;

            }
        },
        async getWorkSpaceData() {
            try {
                this.options = await BaseService.getIssuesForWorkspace({active:1});
                this.backupOptions = JSON.parse(JSON.stringify(this.options))
            }
            catch (e) {
                console.log(e)
            }
        },
        async getProjectData() {
            try {
                this.defaultData = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
                console.log(this.defaultData,'defaultdataaaapppppppp')
                let res = await BaseService.getProjects({ workSpaceId: this.defaultData.workspace.id, accountId: this.defaultData.account.id,active:1 });
                this.options = res && res.projects ? res.projects : []
                this.backupOptions = JSON.parse(JSON.stringify(this.options))
            }
            catch (e) {
                this.options = []
                console.log(e)
            }
        },
        async getTaskGroupData() {
            try {
                this.defaultData = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
                console.log(this.defaultData, 'check333333')
                let res = await BaseService.taskGroup({ project_id: this.defaultData.project.id,active:1 });
                console.log(res, 'check111')
                this.options = res
                this.backupOptions = JSON.parse(JSON.stringify(this.options))

            }
            catch (e) {
                this.options = []
                console.log(e)
            }
        },
        searchData() {
            if (this.search.length > 0) {
                let searchStr = new RegExp(this.search, 'gi');
                this.options = this.backupOptions.filter(ele => {
                    return ele.name.search(searchStr) > -1 || ele.name.search(searchStr) > -1

                })
            }
            else {

                this.options = JSON.parse(JSON.stringify(this.backupOptions))
            }
        },
        async getAccountData() {
            try {
                if(this.accountByEmailData){
                    let obj ={
                        "code":this.accountByEmailData.code,
                         "id":this.accountByEmailData.id,
                         "name":this.accountByEmailData.name,
                    }
                    this.$emit('itemSelected',obj)
                }
                else{
                this.defaultData = JSON.parse(localStorage.getItem("_WS_DEFAULTS_"))
                let res = await BaseService.getAccounts({ workSpaceId: this.defaultData.workspace.id,active:1 });
                this.options = res && res.accounts ? res.accounts : []
                this.backupOptions = JSON.parse(JSON.stringify(this.options))
                }
            }
            catch (e) {
                this.options = []
                console.log(e)
            }
        }

    }
}
</script>
<style scoped>
.item{
    font-size: 13px;
}
#list-Item:hover{
   color: #1258ab;

}
.save {
  grid-area: content;
  width: 30px;
  /* right: 10px; */
  justify-content: center;
  white-space: nowrap;
  color: white;
  background-color: "#3c5aaae";
}
</style>



 <template>
    <v-autocomplete density="compact" 
    variant="outlined" style="width:100%"></v-autocomplete>
</template>
<script>
export default{
    data(){
       
    },
    methods:{
      
    }
}
</script>
<style scoped>
>>>.v-field__outline {
     --v-field-border-width: 0px !important;
    border: 10px !important;
    --v-field-border-opacity: 0.38;
    align-items: stretch;
    contain: layout;
    display: flex; 
    /* background-color:#ECECEC; */
    /* height: 100%;
     left: 0;
    pointer-events: none;
    position: absolute;
    right: 0; 
    width: 100%;
    border: 0px solid #D7D6D6 !important;
    border-radius: 8px !important; */
}


>>>.v-field {
    cursor: text;
    background:#ECECEC !important; 
    font-size: 12px;
    color: #777777;
    border-radius: 8px;
}
>>>.v-field:focus-within {
    cursor: text;
    background-color:#ffffff !important;
    border: 2px solid #3C5AAA !important;
    border-radius: 8px;
}
>>>.v-field--disabled {
    pointer-events: none;
    opacity: 0.8 !important;
}
>>>.custom-autocomplete .v-menu__content .v-list-item .v-list-item__title {
  font-size: 12px; /* Adjust the font size as needed */
}

>>>.custom-autocomplete .v-menu__content .v-list {
  max-height: 100px; /* Adjust the max-height as needed */
}

>>>.custom-autocomplete .v-menu__content {
  width: 50px; /* Adjust the width as needed */
}
.v-select__selections {
     min-height: 30px
}

</style> 


import ApiService from './apiService';
import lodash from 'lodash';
import moment from 'moment-timezone';

const querystring = require('querystring')
class commonService {
    constructor() {

    }
    getTables($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "common/tables"
            $options.method = "get"
            if ($data) {
                $options.url = "common/tables?" + querystring.stringify($data)
                console.log($options.url)
            }
            console.log($options, 'testtttttttttt')
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
    
    changeTheDateToUtc(date,timezone){
        console.log(date,timezone,"changeTheDateToUtc")
        let parsedDateTime = moment.tz(date, "YYYY-MM-DDTHH:mm:ss", timezone);
        let utcDateTime = parsedDateTime.utc().format();
        return utcDateTime
    }
    async getSort(data, key, type) {
        try {
            let $data = lodash.orderBy(data, [key], [type]);
            return $data
        }
        catch (e) {
            console.log(e)
            return data
        }
    }
    getObjectData(data, key, value) {
        if (data.length > 0 && key) {
            let $data = data.filter(ele => {
                return ele[key] == value
            })
            return $data.length > 0 ? $data[0] : null
        }

    }
    getTitleCase(data) {
        return data.toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

    }
    getMultipleChip(data, idData, $key) {
        let $chipData = []
        if (data && data.length > 0 && idData && idData.length) {
            for (let i = 0; i < idData.length; i++) {
                let index = data.findIndex(k => k[$key] == idData[i])
                if (index > -1) {
                    $chipData.push(data[index])
                }
            }
            return $chipData
        }
        else {
            return []
        }
    }
    getObjectSliceMethod(data, idData, $key) {
        let $data = JSON.parse(data)
        if ($data && $data.length > 0 && idData && idData.length) {
            for (let i = 0; i < idData.length; i++) {
                let index = $data.findIndex(k => k[$key] == idData[i])
                if (index > -1) {
                    $data.splice(index, 1)
                }
            }
            return $data
        }
        else {
            return $data
        }
    }
    smallDialog() {
        let size = {
            width: '45%',
            height: '90%'
        }
        return size;
    }
    mediumDialog() {
        let size = {
            width: '800',
            height: '90%'
        }
        return size;
    }
    largeDialog() {
        let size = {
            width: '1200',
            height: '90%'
        }
        return size;
    }
    xSmallAvatar() {
        let size = {
            size: 10,
            font: '5px'
        }
        return size;
    }
    smallAvatar() {
        let size = {
            size: 20,
            font: '10px'
        }
        return size;
    }
    mediumAvatar() {
        let size = {
            size: 30,
            font: '15px'
        }
        return size;
    }
    largeAvatar() {
        let size = {
            size: 40,
            font: '20px'
        }
        return size;
    }

}
export default new commonService();
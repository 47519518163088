import axios from 'axios';
var publicPages = ["login", "otpVerification", "simcard", "resetpassword"]
import apiConfig from '../config/config'
var headers = {
    'Content-Type': 'application/json',

}
const ApiService = {
    getHeaders() {
        return {
            'Content-Type': 'application/json'
        }
    },
    request: async function ($options) {
        return new Promise(function (resolve, reject) {
            if (publicPages.indexOf($options.url) >= 0) {
                console.log("waiting")
            }
            else {
                var $token = (localStorage.getItem('TOKEN'))
                 headers = {
                    'Content-Type': 'application/json',
                    'Authorization': $token,
                    'mobile': true,

                }
            }
            $options.url = apiConfig.baseUrl + "/" + $options.url;
            console.log($options)
            if ($options.method.toUpperCase() == 'POST') {

                let $h
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: this.getHeaders() }
                } else {
                    $h = { headers: headers }
                }
                axios.post($options.url, $options.data, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log("1")
                        reject(error)
                        console.log(error);

                    });
            } else if ($options.method.toUpperCase()  == 'DELETE') {
                axios.delete($options.url, { "headers": this.getHeaders() })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log("2")
                        console.log(error);

                    });
            } else if ($options.method.toUpperCase()  == 'PUT') {
                axios.put($options.url, $options.data, { headers: this.getHeaders() })
                    .then(response => {
                        //console.log(response);
                        resolve(response);
                    })
                    .catch(error => {
                        
                        console.log("3")
                        reject(error)
                        console.log(error);

                    });
            } else {
                var $h = {}
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: this.getHeaders() }
                } else {
                    $h = { headers: headers }
                }
                axios.get($options.url, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(async error => {
                        if(error && error.response && error.response.data && error.response.data.meta &&  error.response.data.meta.message =='Token Expired!'){
                            localStorage.clear()
                            window.location.reload()
                            console.log(error);
                        }
                        else{
                            reject(error)
                        }
                    });
            }


        });


    }

}

export default ApiService
import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import PrimeVue from 'primevue/config'


loadFonts()

window.Office.onReady(() => {createApp(App)
  .use(vuetify)
  .use(PrimeVue)
  .mount('#app')

})


